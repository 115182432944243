import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import png1 from "../../Assets/tridia/1.png";
import png2 from "../../Assets/tridia/2.png";
import png3 from "../../Assets/tridia/3.png";
import "../Projects/project2.css";


const images = [
  { image: png1, desc: 'Giriş ekranımızda gezebileceğimiz şehirlerin listesi ve arama butonumuz bulunmaktadır.' },
  { image: png2, desc: 'Gezmek istediğimiz şehrimizi seçtikten sonra şehrimize ait gidebileceğimiz yerlerin listesinin bulunduğunu ekranımız.' },
  { image: png3, desc: 'Gitmek istediğimiz yeri seçtikten sonra detaylı açıklama sayfamız bizi karşılıyor bu sayfamızda mevcut konuma gidebilmemiz için bizi google haritalar üzerinden konuma yönlendiren bir butonumuz mevcut.' },
]

function Project2() {
  return (
    <Container fluid className="project-section deneme2">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12}>
                <Carousel>
              {images && images.map((data, index) => {
                return <div className=" deneme "  key={index}>
                  <img src={data.image} />
                  <p className="legend">{data.desc}</p>
                </div>
              })}

            </Carousel>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Project2;
