import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../Projects/project2.css";
import { Carousel } from 'react-responsive-carousel';
import png1 from "../../Assets/shopping/1.png";
import png2 from "../../Assets/shopping/2.png";
import png3 from "../../Assets/shopping/3.png";
import png4 from "../../Assets/shopping/4.png";
import png5 from "../../Assets/shopping/5.png";
import png6 from "../../Assets/shopping/6.png";




 const images = [
   { image: png1, desc: 'Uygulamama shoppingassistant.com.tr üzerinden giriş yaptıktan sonra ürün servis sayfasından işletmeye ait ürünler kayıt edilerek elvanter oluşturulur.' },
   { image: png2, desc: 'Ürün satış sayfası barkod okuyucu yardımı ile ürünlerin barkodları okundukça fiyatlarını toplayan ve nakit satışta para üstü hesaplayan bir sayfadır' },
   { image: png3, desc: 'Burada satış sayfamın aydınlık tema görüntüsü görünmektedir.' },
   { image: png4, desc: 'Burada servis sayfamın aydınlık tema görüntüsü görünmektedir.' },
   { image: png5, desc: 'Bu resimde uygulamamın telefon ekranlarındaki görüntüsü gösterilmektedir. Ürünlerin telefon üzerinden de servis ekranında yönetimi sağlanmaktadır.' },
   { image: png6, desc: 'Bu resimde uygulamamın telefon ekranlarındaki görüntüsü gösterilmektedir. Ürünlerin telefon üzerinden satış işlemi gerçekleştirilebilmektedir. Telefonun kamerası kullanılarak ürünlerin barkodu okunur ve satış işlemi için hazırlanır.' },
 ]
function Project4() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12}>
           <Carousel>
              {images && images.map((data, index) => {
                return <div className="deneme" key={index}>
                  <img  src={data.image} alt="Örnek görüntü" />
                  <p className="legend">{data.desc}</p>
                </div>
              })}
            </Carousel> 
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Project4;
