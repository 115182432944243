import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import myImg from "../../Assets/avatar.png";
// import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Merhaba ben <span className="purple"> Muhammed İbrahim Doğan </span> 
            </h1>
            <p className="home-about-body">
              Programlama konusunda her zaman meraklı ve çalışmaya seven biriyim.
              <br />
              <br />Üzerinde çalışmayı sevdiğim alanlar
              <i>
                <b className="purple"> React.js, React Native, .Net 8 ile Api ,C# ,SQL </b>
              </i>
              <br />
              <br />
              İlgi alanlarım ise &nbsp;
              <i>
                <b className="purple">Web sitesi, mobil uyglamalar, Otomasyon yazılımlarıdır. </b> 
              </i>
              <br />
              <br />
              Yazılım alanında kendimi <b className="purple">Udemy, Youtube, Stack Overflow </b> gibi yazılım geliştirmede yardımcı uygulamalar ile 
               <b className="purple"> React kütüpanesi, APİ Projeleri için ise .net 8</b> alanlarında geliştirmekteyim.          
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            {/* <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Sosyal Medya</h1>
            <p>
              Benimle <span className="purple">Bağlantı </span>kurabilirsiniz
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/MuhammedIbrahimDogan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Mibrahimdogan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/muhammed-ibrahim-dogan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/muhammedibrahimdogan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
