import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../Projects/project2.css";
import { Carousel } from 'react-responsive-carousel';
import png1 from "../../Assets/soyagaci/1.png";
import png2 from "../../Assets/soyagaci/2.png";
import png3 from "../../Assets/soyagaci/3.png";

 const images = [
   { image: png1, desc: 'Giriş ekranında bulunan kişinin bilgilerini girdikten sonra oluştur butonu ile hastanın aile soy ağacı oluşturulmaktadır.Kayıtlı bilgilerde bu sayfadan düzenlenmek veya görüntülenmek üzere tekrar açılabilmektedir.' },
   { image: png2, desc: 'Bu aşamada hasta ve aile bireyleri üzerine detaylı olarak not alınabilmektedir.Hasta bilgileri belirlenen formatta kayıt altına alınıp tekrar düzenlenebilmektedir.' },
   { image: png3, desc: 'Son sayfamızda ise üzerine not alınmış hastamız görülmektedir. Buradan pdf formatında veya tekrar işlenebilen formatta kayıt oluşturulabilmektedir.' },
 ]
function Project3() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12}>
           <Carousel>
              {images && images.map((data, index) => {
                return <div className="deneme" key={index}>
                  <img  src={data.image}  alt="Örnek görüntü"/>
                  <p className="legend">{data.desc}</p>
                </div>
              })}
            </Carousel> 
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Project3;
