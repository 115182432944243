import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../Projects/project2.css";

import png1 from "../../Assets/ica/1.png";
import png2 from "../../Assets/ica/2.png";
import png3 from "../../Assets/ica/3.png";
import png4 from "../../Assets/ica/4.png";
import png5 from "../../Assets/ica/5.png";
import png6 from "../../Assets/ica/6.png";
import png7 from "../../Assets/ica/7.png";
import png8 from "../../Assets/ica/8.png";
import png9 from "../../Assets/ica/9.png";
import png10 from "../../Assets/ica/10.png";
import png11 from "../../Assets/ica/11.png";
import png12 from "../../Assets/ica/12.png";
import png13 from "../../Assets/ica/13.png";

const images = [
  { image: png1, desc: 'Giriş ekranında ldap bağlantısı ile belirlenen kullanıcıların giriş yapması sağlanmaktadır.' },
  { image: png2, desc: 'Cihaz kayıt ekranında cihaz bilgileri girilir ve belirlenen formatta seri numarası verilerek kayıt yapılır.' },
  { image: png3, desc: 'Kayıtlı cihazların mevcut durumları değiştirilebilir ve notlar alınır alınan notlara tarih atanır.' },
  { image: png4, desc: 'Giriş ekranında bulunan listeden seçim kısımları için sonradan eklenebilecek alanların dahil edilebileceği alan açma sayfasıdır.' },
  { image: png5, desc: 'LDAP ve LOCAL kullanıcı bilgilerinin değiştirilebileceği alandır.' },
  { image: png6, desc: 'Web sitesi üzerinden kayıtlı cihazların sorgulanabileceği sayfamızın giriş ekranıdır giriş ekranında sunucu bağlantısının hazır olup olmadığını gösteren göstergeler yer alır.' },
  { image: png7, desc: 'Cihazlarda bulunan ve her cihazın kendisine ait olan bizim kayıt sayfamızda atadığımız seri numarası cihazlara karekod olarak basılır ve web sayfasımızda bulunan karekod tarayıcı ile taratılarak sorgu yapılır.' },
  { image: png8, desc: 'Karekod okunamazsa bozulmaya uğradıysa cihazın isimlendirilmesinde kullanılan anahtar kelimeler veya özellikleri ile arama yapılarak bulunabilir.' },
  { image: png9, desc: 'Bu kısımda cihazın detaylı bilgilerinin bulunduğu kısımdır.Sayfanın altında ise cihazın gördüğü işlemler listelenir.' },
  { image: png10, desc: '' },
  { image: png11, desc: '' },
  { image: png12, desc: '' },
  { image: png13, desc: '' }
]


function Project1() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12}>

            <Carousel>
              {images && images.map((data, index) => {
                return <div className="deneme" key={index}>
                  <img src={data.image} />
                  <p className="legend">{data.desc}</p>
                </div>
              })}

            </Carousel>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Project1;
