import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ica from "../../Assets/Projects/ica.png";
import tridia from "../../Assets/Projects/Tridia.png";
import soyagaci from "../../Assets/Projects/soyagacı.png";
import shopping from "../../Assets/Projects/shopping.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
           <strong className="purple">ÇALIŞMALARIM </strong>
        </h1>
        <p style={{ color: "white" }}>
         Öğrendiğim bilgiler doğrultusunda yapmış olduğum çalışmalarım.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ica}
              isBlog={false}
              title="ICA"
              description="Erciyes Anadolu Holding bünyesinde gerçekleştirilen Genç Yetenek Programı kapsamında yapmış olduğum otomasyon yazılımımda karekod ile cihazlar kimliklendirilmiş ve takibi yapılmıştır."
               ghLink="./project1"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tridia}
              isBlog={false}
              title="Tridia"
              description="Türkiye için ülke içi turizmde rehber olarak kullanılabilecek şehirlerin kendilerini temsil eden tarihi noktalar hakkında bilgilerin bulunduğu bir mobil uygulamadır."
               ghLink="./project2"           
            />
          </Col> 
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={soyagaci}
              isBlog={false}
              title="Soyağacı"
              description="Erciyes üniversitesi bitirme ödevi kapsamında yapmış olduğum bu projede aile bilgileri ile soy ağacının oluşturulması ve üzerine notlar alınarak kayıt alınması sağlanmaktadır."
               ghLink="./project3"           
            />
          </Col> 
        </Row>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shopping}
              isBlog={false}
              title="Satış Asistanı"
              description="Yapmış olduğum satış asistanı henüz geliştirilmeye devam edilen fakat ilk kullanıma uygun versiyonu da yayınlanmış olan market otomasyonudur."
               ghLink="./project4"           
            />
          </Col> 
      </Container>
    </Container>
  );
}
export default Projects;
